.wrapper {
  @apply font-serif;

  &:global(.has-placeholder) {
    @apply relative overflow-hidden;
    &:after {
      @apply content-["PLACEHOLDER"] text-red-100/50 text-center absolute z-10 top-0 left-0;
      @apply text-4xl lg:text-[6rem] leading-none font-serif font-bold;
      @apply top-[25%] left-[25%] -rotate-12;
    }
  }

  &:global(.has-grid) {
    @apply grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-8 items-center;

    & :global(.source) {
      @apply md:col-span-2;
    }

    & :global(.labels-wrapper) {
      @apply flex justify-between items-center;

      & p {
        @apply text-2xl font-serif max-w-1xl;
      }

      & ul {
        @apply lg:ml-10 mx-auto;
      }

      & li {
        @apply lg:mb-6;
      }

      & svg {
      }

      & :global(.color) {
        @apply h-4 w-12 inline-block;
      }

      & :global(.value) {
        @apply font-serif font-bold text-base lg:text-2xl;
      }

      & :global(.label) {
        @apply font-serif font-normal text-base lg:text-2xl pl-5 pr-2;
      }
    }

    & :global(.pie-content) {
      @apply flex flex-col md:flex-row justify-center md:justify-start items-center;
      @apply md:mt-10 lg:mt-0;

      & svg {
        @apply w-[34px] h-[16px] flex-shrink-0 rotate-90 md:rotate-0 md:mr-5;
      }

      & p {
        @apply text-2xl font-serif max-w-1xl mt-4 md:mt-0 flex-grow;

        & span {
          @apply font-bold;
        }
      }
    }
  }

  &:global(.has-map) {
    & :global(.illustration) {
      @apply flex justify-center md:justify-end;
      & svg {
        @apply w-full max-w-[338px] md:pl-10;
      }
    }

    & :global(.color) {
      @apply mr-5;
    }
  }

  & :global(.two-columns) {
    @apply grid grid-cols-1 sm:grid-cols-2 gap-x-10 gap-y-8 mx-auto max-w-[856px];
    @apply text-center;

    & :global(.illustration-wrapper) {
      @apply sm:aspect-w-[408] sm:aspect-h-[576] relative mb-4;
      & svg {
        @apply w-full sm:absolute sm:bottom-0;
      }
    }

    & p {
      @apply text-2xl font-serif max-w-1xl pt-4;
    }

    & :global(.number) {
      @apply font-sans text-green-100/75 font-bold text-8xl leading-none;
    }

    & :global(.unit) {
      @apply font-sans text-green-100/75 font-bold text-6xl leading-none;
    }
  }

  & :global(.source) {
    @apply font-sans text-center pt-6 max-w-1xl mx-auto;

    & a {
      @apply font-bold no-underline text-green-100;
    }
  }
}
